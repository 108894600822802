import React from "react";
import styles from "./pages.module.scss";
import { Link } from "gatsby";

import Banner from "../components/banner";

export default () => (
  <div className={styles.Page}>
    <Banner page="404" />
    <div className={styles.error_container}>
      <h1>Oops, something went wrong.</h1>
      <p>
        Navigate <Link route="/">back to home</Link>
      </p>
    </div>
  </div>
);
